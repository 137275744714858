/** @jsx jsx */
import { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import ExploreLayout from '@sprinklr/website/exploreSrc/components/ExploreLayout';
import { jsx } from 'theme-ui';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { filterDataByLocale } from '../../../../../shared-lib/utils';
import NoLocaleContentTemplate from '../../../../src/templates/customTemplate/noLocaleContent/NoLocaleContent';
import { CardFullDisplay } from '../../../../../shared-lib';
import {
  productFilterOptions,
  eventsTypeFilterOptions,
  productLookup,
} from '../components/filters';

export const demoDaysIndexPageQuery = graphql`
  query (
    $language: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $pageName: String! = "DEMO_DAYS"
  ) {
    ...HeaderFooterSocialQuery
    ...MetaDataByPageName
    ...DemoDaysIndexQuery
    ...ExploreSiteMetadataQuery
    ...LocalesQuery
  }
`;

const DemoDaysIndexPage = ({ data, pageContext }) => {
  const {
    allContentfulEvent,
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    allContentfulMetadata,
    site,
  } = data;

  const { nodeLocale } = pageContext;
  const metadata = allContentfulMetadata?.nodes[0];

  const { t } = useI18next();

  const demoDays = allContentfulEvent?.nodes;
  const localizedDemoDays = useMemo(
    () => filterDataByLocale(demoDays, nodeLocale, 'allLocalesToShowCard'),
    [],
  );

  const [productFilter, setProductFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [filteredDemoDays, setFilteredDemoDays] = useState(localizedDemoDays);

  localizedDemoDays.forEach(
    el =>
      (el.pastOrFuture =
        new Date(el.date) < new Date() ? 'onDemand' : 'upcoming'),
  );

  useEffect(() => {
    const productFilteredDemoDays =
      productFilter === 'all'
        ? localizedDemoDays
        : localizedDemoDays.filter(
            e =>
              e.relatedProducts &&
              e.relatedProducts.includes(productLookup[productFilter]),
          );
    const typeSortedDemoDays =
      typeFilter === 'all'
        ? productFilteredDemoDays
        : productFilteredDemoDays.filter(
            e => e.pastOrFuture && e.pastOrFuture === typeFilter,
          );

    setFilteredDemoDays(typeSortedDemoDays);
  }, [productFilter, typeFilter]);

  const filterDetails = [
    {
      customDropdownLabel: 'Product',
      options: productFilterOptions,
      value: productFilter,
      setValue: setProductFilter,
    },
    {
      customDropdownLabel: 'Type',
      options: eventsTypeFilterOptions,
      value: typeFilter,
      setValue: setTypeFilter,
    },
  ];

  return (
    <ExploreLayout
      context={pageContext}
      slug={'/demo-days'}
      metadata={metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
    >
      <CardFullDisplay
        headline={t('Sprinklr Product Demo Days')}
        subHeadline={t('demoDaysSubheadline')}
        overallPageType={'event'}
        cards={filteredDemoDays}
        filterDetails={filterDetails}
        cardSlugPrefix="events"
        shouldHideNoResultsMessage={!localizedDemoDays?.length && true}
        nodeLocale={nodeLocale}
        cardfullDisplaySx={{ pt: ['40px', 0, 0, '40px'] }}
      />
      {!localizedDemoDays?.length && (
        <NoLocaleContentTemplate padding={{ top: 'NONE', bottom: 'LARGE' }} />
      )}
    </ExploreLayout>
  );
};
export default DemoDaysIndexPage;
